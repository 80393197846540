@import '/src/scss/main';

.employee-hero {
  @include content-wrapper;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: $color--blue--dark;
  margin-top: $spacing--m;
  margin-bottom: $spacing--sm;

  @include breakpoint-m {
    margin-bottom: $spacing--xl;
  }

  @include breakpoint-sm {
    flex-direction: row-reverse;
  }

  &__content {
    width: 100%;
    margin-left: 0;
    margin-top: $spacing--sm;

    @include breakpoint-sm {
      margin-left: $spacing--sm;
      margin-top: 0;
    }

    @include breakpoint-sm {
      width: 80%;
    }
  }

  &__title {
    .heading {
      font-size: 26px;
      font-weight: 500;
      @include breakpoint-l {
        font-size: 50px;
      }
    }
  }

  &__sub-title {
    line-height: 1.23;
    font-size: 21px;
    margin-top: $spacing--xxs;
    @include breakpoint-l {
      font-size: 26px;
      font-weight: 500;
    }
  }

  &__description {
    line-height: 1.3;
    font-size: 16px;
    margin: $spacing--xs 0 $spacing--s 0;
    @include breakpoint-m {
      font-size: 21px;
      max-width: 80%;
    }
  }

  &__contact {
    display: block;
    font-size: 16px;

    @include breakpoint-s {
      display: flex;
    }

    &-item {
      display: flex;
      margin-bottom: $spacing--s;

      @include breakpoint-s {
        margin-bottom: 0;
        &:first-child {
          margin-right: $spacing--l;
        }
      }
    }

    &-icon {
      margin-right: $spacing--xxs;
    }

    &-label {
      font-weight: 500;
      margin-bottom: $spacing--xxxs;
    }

    &-link {
      text-decoration: none;
      color: $color--blue--dark;
      padding-bottom: 1px;
      transition: border-bottom 0.3s;
      border-bottom: 1px solid $color--blue--dark;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  &__image {
    aspect-ratio: 16 / 16;
    position: relative;
    width: 70%;
    border-radius: 50%;
    overflow: hidden;

    @include breakpoint-sm {
      width: 30%;
    }

    picture img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
    }
  }
}
