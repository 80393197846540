@import '/src/scss/main';

.frontpage-hero {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background: #EAF9FA;
  padding-bottom: $spacing--sm;

  @include breakpoint-m {
    display: block;
    background: transparent;
  }

  &__content {
    @include content-wrapper;
    display: flex;
    flex-direction: column;
    padding-top: $spacing--xxs;

    @include breakpoint-m {
      position: absolute;
      height: 100%;
      justify-content: center;
      padding-top: 0;
    }
  }

  &__title {
    max-width: 377px;
    margin-bottom: $spacing--xxs;

    @include breakpoint-m {
      margin-bottom: $spacing--xs;
    }
  }

  &__text {
    @include responsive-font-size(21px, 26px);
    color: $color--blue--dark;
    max-width: 448px;
    font-weight: 200;
    margin-bottom: $spacing--s;
  }

  &__button {
    z-index: 2;
  }

  video {
    width: 100%;
  }
}
