.rich-text-block {
  @include content-wrapper;
  max-width: 1200px;
  margin-bottom: $spacing--sm;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint-m {
    margin-bottom: $spacing--l;
  }

  @include breakpoint-l {
    flex-direction: row;
    justify-content: space-between;
  }

  &__sidebar {
    background: $color--blue--light;
    margin-bottom: $spacing--s;

    .location-shortcut {
      padding: 0;
      margin: 0;

      ul {
        margin: 0;
      }
    }

    @include breakpoint-l {
      width: 30%;
      height: fit-content;

      .location-shortcut {
        padding: 0;
        margin: 0;

        ul {
          margin: 0;
        }
      }

    }
  }

  &__content {
    max-width: 752px;

    @include breakpoint-l {
      width: 70%;
      margin-right: $spacing--xxl;
    }

    .promo {

      &__content {
        padding: $spacing--sm $spacing--sm $spacing--sm $spacing--sm;

      }

      &__title h2 {
        @include responsive-font-size(21px, 26px);
      }

      &__button .button {
        margin-bottom: 0;
      }

    }

  }
}