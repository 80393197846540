@import '/src/scss/main';

.section-entrance-list {
  @include content-wrapper;

  &__items {
    list-style: none;
    padding: 0;
  }

  &__item {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: $spacing--m;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint-sm {
      margin-bottom: $spacing--sm;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;

      &:nth-child(2n) {
        flex-direction: row;
      }

      &:nth-child(2n) &-link {
        margin-right: $spacing--m;
      }

      &:nth-child(odd) &-link {
        margin-left: $spacing--m;
      }
    }

    &-link {
      @include arrow-hover;
      text-decoration: none;

      @include breakpoint-sm {
        width: 50%;
      }
    }

    &-title-container {
      display: flex;
      align-items: center;
      margin-top: $spacing--s;

      @include breakpoint-sm {
        margin-top: 0;
      }

      .heading {
        font-size: 26px;

        @include breakpoint-s {
          font-size: 37px;
        }

        @include breakpoint-l {
          font-size: 50px;
        }
      }
    }

    &-title {
      @include breakpoint-sm {
        max-width: 70%;
      }
    }

    &-icon {
      width: 50px;
      height: 25px;
      margin-left: $spacing--s;
    }

    &-text {
      color: $color--blue--dark;
      font-weight: 200;
      font-size: 21px;
      line-height: 1.23;
      margin: $spacing--xxs 0 0 0;

      @include breakpoint-l {
        font-size: 26px;
        width: 90%;
      }
    }

    &-image {
      aspect-ratio: 640 / 410;
      position: relative;
      width: 100%;
      border-radius: 262px;
      overflow: hidden;

      @include breakpoint-sm {
        width: 50%;
      }

      picture img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
      }
    }
  }
}