@import 'src/scss/main';

.pagination {
  @include content-wrapper;
  display: flex;
  list-style: none;
  &__input {
    visibility: hidden;
    display: none;
  }

  &__item {
    display: flex;
    margin-top: $spacing--xxs;
    margin-left: $spacing--xs;
    margin-right: $spacing--xs;
    border-bottom: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    background: $color--blue--dark;
    color: white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    min-width: 100%;
    line-height: 1;
    text-decoration: none;
    border: 2px solid $color--blue--dark;
    text-align: center;
    justify-content: center;
    padding: $spacing--xxxs $spacing--xxs;
    border-radius: 14px;

    @include breakpoint-l {
      &:hover {
        color: $color--blue--dark;
        background: $color--blue--light;
        cursor: pointer;
      }
    }

    &--is-selected {
      background: $color--blue--light;
      color: $color--blue--dark;
    }
  }
}
