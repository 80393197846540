@import '/src/scss/main';

.link-list {
  @include content-wrapper;
  display: flex;
  flex-direction: column;

  ul {
    columns: 1;
    padding-left: 0;

    @include breakpoint-s {
      columns: 2;
      column-gap: $spacing--sm;
    }
  }

  li {
    list-style: none;
    padding: $spacing--xxs;
    border-bottom: 1px solid $color--blue--dark;
    break-inside: avoid;
  }

  &__link {
    @include arrow-hover;
   
    a { 
      @include responsive-font-size(16px, 21px);
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: $color--blue--dark;
    }

    &-arrow {
      padding-left: 2rem;
      margin-top: 2px;
      width: 26px;
    }
  }
}