@import 'src/scss/main';

.promoted-page {
    @include content-wrapper;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint-m {
        flex-direction: row;
    }

    &__content {
        width: 100%;
        margin-right: $spacing--xl;

        @include breakpoint-m {
            width: 40%;
        }
    }

    &__title {
        margin-bottom: $spacing--xs;
        margin-top: $spacing--xs;

        @include breakpoint-m {
            margin-bottom: $spacing--m;
            margin-top: 0;
        }

        @include breakpoint-l {
            max-width: 80%;
        }

        .heading {
            font-size: convert-to-rem(37px);

            @include breakpoint-l {
                font-size: convert-to-rem(67px);
            }
        }
    }

    &__image {
        width: 100%;
        aspect-ratio: 640 / 410;
        position: relative;
        overflow: hidden;

        @include breakpoint-m {
            width: 60%;
        }
      
        picture img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover; object-position: center;';
            }
          
    }

}
