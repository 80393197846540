@import '/src/scss/main';

.mega-menu-block {
  background: white;
  z-index: 9;
  width: 100%;
  position: fixed;
  top: 0;
  transition: top 0.8s;

  &--hidden {
    top: -161px;
    transition: top 0.8s;
  }

  &__top-content {
    margin-bottom: 20px;
    @include breakpoint-l-max {
      @include content-wrapper;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $spacing--s;
    }
  }

  &__logo {
    height: 32px;
    @include breakpoint-l {
      height: 72px;
    }
    svg {
      width: 110px;
      height: 32px;

      @include breakpoint-l {
        width: 247px;
        height: 72px;
      }
    }
  }

  &__content-container {
    display: block;
    justify-content: space-between;
    padding-top: $spacing--s;
    align-items: center;

    @include breakpoint-l {
      @include content-wrapper;
      padding-left: 18px !important;
      padding-right: 18px !important;
      display: flex;
      padding-top: $spacing--s;
      padding-bottom: 0 !important;
    }
  }

  &__content {
    z-index: 9;
    list-style: none;
    background: $color--blue--dark;
    padding-top: $spacing--s;
    padding-bottom: $spacing--sm;
    margin: 0;
    position: fixed;
    right: -1500px;
    overflow-y: auto;
    bottom: 0;
    top: 80px;
    transition: right 0.8s;
    width: 100%;
    display: flex;
    flex-direction: column;

    &--is-open {
      transition: right 0.5s;
      right: 0;
    }
    @include breakpoint-l {
      width: auto;
      overflow: hidden;
      position: static;
      padding: 0;
      max-height: none;
      flex-direction: row;
      background: white;
      align-items: center;
    }
  }

  &__groups-container {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    @include breakpoint-l {
      flex-direction: row;
      align-items: center;
    }
  }

  &__groups {
    @include content-wrapper;
    padding-top: $spacing--xxs;
    padding-bottom: $spacing--xxs;
    margin: 0 !important;

    @include breakpoint-l-max {
      order: 2;

      &:first-child {
        padding-bottom: $spacing--s;
        a {
          font-size: 16px;
        }
      }
    }

    @include breakpoint-l {
      padding: 0;
    }
  }

  &__items-container {
    width: 100%;
    overflow: hidden;
    transition: all 0.3s;

    @include breakpoint-l {
      position: fixed;
      left: 0;
      margin-top: 31px;
      background: $color--blue--dark;
      max-height: calc(100vh - 172px);
      overflow: auto;
    }
  }

  &__items {
    padding-top: $spacing--xs;
    padding-bottom: $spacing--xs;

    @include breakpoint-l {
      @include content-wrapper;
      display: flex;
      padding-top: $spacing--m;
      padding-bottom: $spacing--m;
    }
  }

  &__item {
    list-style: none;
    padding: 0;

    &--with-title {
      margin-right: $spacing--l;
    }
  }

  &__item-container {
    list-style: none;
    margin-bottom: $spacing--s;
    padding: 0;
  }

  &__item-title {
    @include responsive-font-size(16px, 21px);
    color: white;
    font-weight: 500;
    margin-bottom: $spacing--xxs;
  }

  &__link,
  &__link-with-title {
    margin-bottom: $spacing--xs;

    @include breakpoint-l {
      margin-bottom: $spacing--xxs;
    }
  }

  &__link-with-title {
    margin-left: $spacing--xs;
  }

  &__link-text {
    @include responsive-font-size(16px, 21px);
    text-decoration: none;
    color: white;
    font-weight: 500;

    &--with-title {
      font-weight: 400;
    }

    @include breakpoint-l {
      transition: border-bottom 0.3s;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $color--orange;
      }
    }
  }

  &__heading {
    width: 50%;
    display: none;

    @include breakpoint-l {
      display: flex;
      justify-content: center;
    }
  }

  &__button-text {
    padding-top: 1px;
  }

  &__button-text,
  &__nav-link {
    color: white;
    font-weight: 500;

    @include breakpoint-l {
      transition: border-bottom 0.3s;
      border-bottom: 2px solid transparent;
      font-weight: 500;
      color: $color--blue--dark;

      &:hover {
        border-bottom: 2px solid $color--orange;
      }
    }
  }

  &__button-text,
  &__button-icon {
    @include breakpoint-l-max {
      pointer-events: none;
    }
  }

  &__button,
  &__nav-link {
    font-size: 26px;
    text-decoration: none;

    @include breakpoint-l {
      margin-left: $spacing--s;
      font-size: 21px;
    }
  }

  &__nav-link {
    color: white;

    @include breakpoint-l {
      color: $color--blue--dark;
      line-height: 1;
    }
  }

  &__button {
    @include button-style-reset;
    background: transparent;
    display: none;
    padding: 0;

    &-icon {
      margin-left: $spacing--xxs;

      svg {
        transition: all 0.3s;
        transform-origin: center;
        margin-bottom: 1px;

        @include breakpoint-l-max {
          width: 22px;
          height: 12px;
        }

        path {
          fill: $color--orange;
        }
      }

      &--is-open {
        svg {
          transform: rotate(-180deg);
          transform-origin: center;
          transition: all 0.3s ease-out;
        }
      }
    }

    @include breakpoint-l {
      display: flex;
      color: $color--blue--light;
    }
  }

  &__button--mobile {
    display: flex;
    @include breakpoint-l {
      display: none;
    }
  }

  &__hamburger-button {
    @include breakpoint-l {
      display: none;
    }

    @include button-style-reset;
    background: transparent;
    width: 32px;
    height: 32px;
    top: 2px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $color--blue--dark;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 6px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 14px;
    }

    span:nth-child(4) {
      top: 22px;
    }

    &--open span:nth-child(1) {
      width: 0%;
      left: 50%;
    }

    &--open span:nth-child(2) {
      transform: rotate(45deg);
    }

    &--open span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &--open span:nth-child(4) {
      width: 0%;
      left: 50%;
    }
  }

  &__booking-button-container {
    @include breakpoint-l-max {
      position: absolute;
      right: 0;
      @include content-wrapper;
    }
  }

  &__booking-button {
    margin-left: $spacing--s;

    @include breakpoint-l-max {
      margin-left: 0;
      .button__text-container {
        border: 3px solid white;
      }

      &:hover .button__text {
        color: white;
      }
    }
  }

  &__top-links {
    @include breakpoint-l-max {
      display: none;
    }
    @include content-wrapper;
    padding-right: 6rem;

    ul {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1em !important;
      margin-bottom: 0;

      a {
        font-size: 16px;
        font-weight: 500;
        color: $color--blue--dark;
        text-decoration: none;
        margin-right: 24px;
        transition: border-bottom 0.3s;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid $color--orange;
        }
      }

      & li:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }

  &__top-links--mobile {
    display: block;
    @include content-wrapper;
    margin: $spacing--s 0 0 0;

    ul {
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin-top: 1em !important;
      align-items: flex-end;
      border-top: 2px solid $color--blue--medium;

      li {
        margin-bottom: $spacing--xxs;

        &:first-child {
          margin-top: $spacing--s;
        }
      }

      a {
        font-size: 16px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        transition: border-bottom 0.3s;
        border-bottom: 2px solid transparent;
        width: fit-content;

        &:hover {
          border-bottom: 2px solid $color--orange;
        }
      }
    }
    @include breakpoint-l {
      display: none;
    }
  }

  &__search {
    margin-right: $spacing--xs;
  }
}
