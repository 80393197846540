$color--blue--dark: #1e4661;
$color--blue--medium: #6d8798;
$color--blue--light: #cbeff4;
$color--blue--grey: #e9edef;
$color--blue--logo: #ace6ed;
$color--yellow: #faf7bf;
$color--grey: rgba(53, 65, 82, 0.08);
$color--grey--light: #f2f2f2;
$color--link: #3b46ad;
$color--orange: #f57330;
$color--orange--light: #fcd6c2;
$color--pink: #f2366b;
$color--green: #24b573;
$color--beige: #ebbe9b;
