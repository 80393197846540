@import 'src/scss/main';

.filter-tags {
  @include content-wrapper;
  max-width: 1200px !important;

  &__title {
    margin-bottom: $spacing--xxs;
  }

  &__list-container {
      max-width: 900px;
  }

  &__list-container-mobile {
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    &--mobile {
      overflow: hidden;
      display: block;
      border-radius: 0 0 25px 25px;
      border-left: 2px solid #354154;
      border-right: 2px solid #354154;
      border-bottom: 2px solid #354154;
      background-color: #defaff;
    }
  }

  &__input {
    visibility: hidden;
    display: none;
  }

  &__item {
    display: flex;
    margin-top: $spacing--xs;
    margin-right: convert-to-rem(38px);
    border-bottom: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    background: $color--blue--dark;
    color: white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    min-width: 100%;
    line-height: 1;
    text-decoration: none;
    border: 2px solid $color--blue--dark;
    text-align: center;
    justify-content: center;
    padding: $spacing--xxxs $spacing--xxs;

    &:hover {
      color: $color--blue--dark;
      background: $color--blue--light;
      cursor: pointer;
    }
    border-radius: 14px;

    &--is-selected {
      background: $color--blue--light;
      color: $color--blue--dark;
    }
  }

  &__label--mobile {
    padding: 1rem 1rem;
    background: $color--blue--light;
    color: $color--blue--dark;
    border: none;
    border-radius: 0;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(53, 65, 82, 0.5);
    &:hover {
      color: white;
      background: $color--blue--dark;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__dropdown {
    @include breakpoint-m {
      display: none;
    }
  }
}
