@import '/src/scss/main';

.service-card-list {
  @include content-wrapper;
  background: $color--blue--light;
  @include breakpoint-m {
    background: transparent;
  }

  &__title {
    margin-bottom: $spacing--s;
    padding-top: $spacing--s;

    @include breakpoint-m {
      padding-top: 0;
    }
  }

  &__items {
    @include grid-4-items;
    list-style: none;
    padding: 0 0 $spacing--xs 0;
    margin: 0;

    @include breakpoint-m {
      padding: 0;
    }
  }

  &__item {
    padding-bottom: $spacing--xs;

    &-link {
      text-decoration: none;
    }

    &-title {
      background: $color--blue--dark;
      padding: $spacing--xxs $spacing--xs;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-image {
      overflow: hidden;
      max-height: 100%;
      display: none;

      @include breakpoint-m {
        display: block;
      }
    }

    img {
      transition: all 0.3s ease-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: all 0.3s ease-out;
      }
    }
  }
}
