table {
  &:not(.CookieDeclarationTable) {
    border: 0.7px solid $color--blue--medium;
    margin: $spacing--sm 0 $spacing--sm 0;

    thead {
      tr {
        line-height: convert-to-rem(28px);
        background-color: $color--blue--dark;

        td,
        th &:not(.datepicker){
          font-size: convert-to-rem(20px);
          font-weight: 500 !important;
          color: #ffffff;
          padding: $spacing--xs 0 $spacing--xs $spacing--xs;
        }
      }
    }

    tbody {
      tr {
        line-height: 1rem;

        &:nth-child(even) {
          background-color: #e9edef;
        }

        &:nth-child(odd) {
          background-color: #ffffff;
        }

        &:first-child &:not(.datepicker){
          line-height: convert-to-rem(28px);
          background-color: $color--blue--dark;

          td {
            font-size: convert-to-rem(20px);
            font-weight: 500;
            color: #ffffff;
            border-top: 0.7px solid $color--blue--medium;
            border-bottom: hidden;

            a {
              color: #ffffff !important;
              border-color: #ffffff;

              &:hover {
                border-bottom: 1px solid transparent;
              }
            }

            p {
              color: #ffffff;
            }
          }
        }

        &:last-child td {
          border-bottom: 0.7px solid $color--blue--medium;
        }

        td {
          padding: $spacing--xs 0 $spacing--xs $spacing--xs;
          font-size: convert-to-rem(16px);
          color: $color--blue--dark;
          border-top: 0.7px solid #bbbbbb;
          border-bottom: 0.7px solid #bbbbbb;
          border-left: 0.7px solid $color--blue--medium;
          border-right: 0.7px solid $color--blue--medium;

          & p {
            font-size: convert-to-rem(16px) !important;
            margin-bottom: $spacing--xs !important;
          }

          & p:last-child {
            margin-bottom: 0 !important;
          }
          a {
            text-decoration: none;
            color: $color--blue--dark !important;
            transition: border-bottom 0.3s;
            border-bottom: 1px solid $color--blue--dark;
            width: fit-content;

            &:hover {
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }
  }
}

table.CookieDeclarationTable {
  margin: $spacing--sm 0 $spacing--sm 0;

  thead {
    tr {
      line-height: convert-to-rem(28px);
      background-color: $color--blue--dark;

      td,
      th {
        font-size: convert-to-rem(20px);
        font-weight: 500 !important;
        color: #ffffff;
        padding: $spacing--xs 0 $spacing--xs $spacing--xs;
      }
    }
  }

  tbody {
    tr {
      line-height: 1rem;

      &:nth-child(even) {
        background-color: #e9edef;
      }

      &:nth-child(odd) {
        background-color: #ffffff;
      }
    }

    td {
      color: $color--blue--dark;
      padding: $spacing--xs 0 $spacing--xs $spacing--xs;
      font-size: convert-to-rem(16px);

      & p {
        font-size: convert-to-rem(16px) !important;
        margin-bottom: $spacing--xs !important;
      }

      & p:last-child {
        margin-bottom: 0 !important;
      }

      a {
        text-decoration: none;
        color: $color--blue--dark !important;
        transition: border-bottom 0.3s;
        border-bottom: 1px solid $color--blue--dark;
        width: fit-content;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}
