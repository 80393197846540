@import '/src/scss/main';
.system-message-block {
  @include content-wrapper;
  list-style: none;
  padding: 0;
  margin: 0 auto 0 auto;

  &__item {
    position: relative;
    margin-top: $spacing--sm;
    margin-bottom: $spacing--sm;
    padding: $spacing--s;

    &--informative {
      background: $color--blue--grey;
    }

    &--warning {
      background: $color--yellow;
    }

    &--critical {
      background: $color--orange--light;
    }

    &-title-container {
      display: flex;
      max-width: 85%;
    }

    &-text {
      margin: $spacing--xxs 0 0 $spacing--sm;
      line-height: 1.3;
      max-width: 800px;
      p {
        font-size: 16px !important;
      }
    }

    &-icon {
      margin-right: $spacing--xxs;
      svg {
        margin-top: 3px;
      }
    }

    &-close-button-container {
      position: absolute;
      right: $spacing--s;
    }

    &-close-button {
      @include button-style-reset;
      background: transparent;
    }
  }
}
