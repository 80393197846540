@use '../../scss/main' as *;
@use './booking-box' as *;

.sectra-booking {
  &__exam-nav {
    overflow: hidden;

    .booking-box-heading {
      padding: $booking-box-container-padding-y $booking-box-container-padding-x
        0;

      @include media-breakpoint-up(lg) {
        padding: $booking-box-container-padding-lg-x
          $booking-box-container-padding-lg-y 0;
      }

      > a {
        text-decoration: none;

        &:active,
        &:hover,
        &:visited {
          color: inherit;
        }
      }
    }
  }

  &__exam-menu {
    position: relative;
    margin: 0;
    padding: 0;
    left: 0;

    &__item {
      padding: 0;

      .sectra-booking__webgroup,
      .sectra-booking__action,
      .sectra-booking__location {
        padding: 16px 20px;
        width: 100%;
      }

      form[data-sectra-booking-form=""],
      form[data-sectra-booking-submit=""] {
        width: 100%;
      }

      &__children {
        display: none;
        position: absolute;
        top: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        left: 100%;
      }

      &--active > &__children {
        display: block;
      }
    }
  }

  &__webgroup {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__prev-button {
    @include reset-input;
    background: transparent;
    display: inline;
  }

  &__list {
    @include clear-list;
  }

  &__list-item {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__action {
    @include reset-input;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    color: $booking-box-main-color;
    font-weight: 500;
    cursor: pointer;
  }

  &__tabs {
    display: flex;
    border-bottom: 1px solid $booking-box-light-gray-color;
  }

  &__tab {
    @include reset-input;

    display: flex;
    flex: 1 1 50%;
    margin-left: $booking-box-container-padding-x;
    padding: 16px 20px;
    text-align: center;
    justify-content: center;
    background-color: $booking-box-light-gray-color;
    font-weight: 500;
    color: $booking-box-main-color;
    border-top: 1px solid $color--orange;
    border-right: 1px solid $color--orange;
    border-left: 1px solid $color--orange;
    cursor: pointer;

    &--active,
    &:hover {
      background-color: transparent;
    }

    &:last-child {
      margin-right: $booking-box-container-padding-x;
    }
  }

  &__back {
    @include reset-input;
    @include hover(pointer);
    @extend .align-items-center;

    background: transparent;
    text-decoration: none;
    color: $booking-box-dark-color;
    display: flex;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 10px;

    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &__button {
    @extend .sectra-booking__action;
    @extend .justify-content-between, .align-items-center;

    display: flex;
    font-size: 13px;
    letter-spacing: 0.86px;
    font-weight: 500;
    color: $color--blue--dark;
    padding-right: 20px;
  }

  &__disclaimer {
    margin: $booking-box-container-padding-y $booking-box-container-padding-x;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.73px;
    line-height: 23px;

    &-button {
      @include buttonStyle();
      border-color: $booking-box-main-color;
      color: $booking-box-main-color;
      margin: 0 $booking-box-container-padding-x;
    }
  }

  &__show-booking {
    @include reset-input;

    text-decoration: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }

  &__location {
    @extend .booking-box-section-list;
    width: 100%;
    border: none;
    appearance: none;
  }

  &__back-button {
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  &__price-info,
  &__details-list {
    padding: 0 $booking-box-container-padding-x;
  }

  &__details-list {
    margin-top: $booking-box-container-padding-y;
  }
}

.preview-text {
  margin: 0 0 4.1rem;
}

.preview-text__heading,
.preview-text__heading--h1,
.preview-text__heading--h2,
.preview-text__heading--h3 {
  margin: 0 0 0.9rem;
}

.preview-text__heading--h3 {
  margin-top: 4rem;
}

.preview-text__subheading {
  display: block;
  font-size: 16px;
  line-height: 1.57;
  font-weight: 400;
  color: #7a838f;
}

.preview-text__add-heading {
  display: block;
  font-size: 1.4rem;
  line-height: 1.57;
  font-weight: 400;
  color: #7a838f;
  margin: 0 0 2.5rem;
}

.preview-text__paragraph {
  margin: 0 0 2.2rem;
}

.preview-text__paragraph.lead {
  font-weight: 400;
}

.preview-text__description {
  margin: 0 0 2.2rem;
  color: #12a395;
}

.preview-text__list {
  margin-bottom: 1.9rem;
}

.preview-text .read-more-opener {
  display: inline-block;
  vertical-align: top;
  margin-top: -1.6rem;
} 

.content-section {
  padding: 3.8rem 0;
}

.content-section > .single-post.light-green-page,
.content-section > .single-post.light-orange-page,
.content-section > .single-post.light-pink-page,
.content-section > .single-post.light-white-page,
.content-section > .single-post.lavender-page,
.content-section > .single-post.palette {
  margin-top: -3.8rem;
}

.content-section > .single-post--header {
  margin-top: -3.8rem;
}

body:not(.no-additional-footer) .content-section {
  padding-bottom: 0;
}

.content-section--text {
  color: #12a395;
  font-size: 1.6rem;
  padding: 0 10px;
  width: 85%;
  margin-top: -95px;
  margin-bottom: 35px;
}

.booking-box__flex {
    display: flex;
    flex-direction: column;

    .preview-text_content {
        margin: auto;
        width: 100%;
        text-align: center;
    }
}