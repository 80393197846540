// when using image in richtext

.image-block {
  font-size: 16px;
  line-height: 1.3;
  margin: $spacing--xxs 0 $spacing--sm 0;

  &__caption {
    display: flex;
    flex: none;

    &-label {
      margin-right: $spacing--xxs;
      font-weight: 500;
    }
  }

  img {
    width: 100%;
  }
}
