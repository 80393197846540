$content-max-width: 1312px;

//SPACING
/*$spacing--xxl: convert-to-rem(96px);
$spacing--xl: convert-to-rem(80px);
$spacing--l: convert-to-rem(64px);
$spacing--m: convert-to-rem(48px);
$spacing--sm: convert-to-rem(32px);
$spacing--s: convert-to-rem(24px);
$spacing--xs: convert-to-rem(16px);
$spacing--xxs: convert-to-rem(8px);
$spacing--xxxs: convert-to-rem(4px);*/

$spacing--xxl: 48px;
$spacing--xl: 48px;
$spacing--l: 48px;
$spacing--m: 48px;
$spacing--sm: 32px;
$spacing--s: 24px;
$spacing--xs: 16px;
$spacing--xxs: 8px;
$spacing--xxxs: 4px;

//BREAKPOINTS
$breakpoint-s: 500px;
$breakpoint-sm: 700px;
$breakpoint-m: 1000px;
$breakpoint-l: 1348px;
$breakpoint-xl: 1500px;