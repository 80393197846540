@import 'src/scss/main';

.factbox {
  background: $color--blue--light;
  width: 100%;
  height: fit-content;

  &__toptext {
    display: flex;
    flex-flow: column;
    padding: $spacing--xs $spacing--xs 0 $spacing--xs;
    color: $color--blue--dark;

    &-title {
      font-weight: 500;
      margin-bottom: $spacing--xxs;
      font-size: 21px;
    }

    &-description {
      p {
        font-size: 16px !important;
      }
    }
  }

  &__contact {
    padding: 0 $spacing--xs 0 $spacing--xs;
    margin-top: 28px;

    &__item {
      display: flex;
      flex-flow: row;
      margin-top: $spacing--s;

      &:last-child {
        @include breakpoint-l{
          padding-bottom: $spacing--xs;
        }
      }

      &__icon {
        height: $spacing--xs;
        width: $spacing--xs;
        margin-right: 12px;
        min-width: $spacing--xs;

        path {
          fill: $color--blue--dark;
        }
      }

      svg {
        max-width: 100%;
        height: auto;
        max-height: 85%;
      }

      &__content {
        display: flex;
        flex-flow: column;
        color: $color--blue--dark;

        &__title {
          margin-bottom: 5px;
          font-weight: 500;
          color: $color--blue--dark;
        }

        &__block {

          a {
            text-decoration: none;
            color: $color--blue--dark;
            padding-bottom: 1px;
            transition: border-bottom 0.3s;
            border-bottom: 1px solid $color--blue--dark;

            &:hover {
              border-bottom: 1px solid transparent;
            }
          }

          &-image {
            display: flex;
            flex-direction: row;
          }

          &-personalia {
            display: flex;
            flex-direction: column;
            color: $color--blue--dark;

            a {
              text-decoration: none;
              color: $color--blue--dark;
              transition: border-bottom 0.3s;
              border-bottom: 1px solid $color--blue--dark;
              width: fit-content;

              &:hover {
                border-bottom: 1px solid transparent;
              }

            }
          }

        }

        &__image {
          margin: 8px $spacing--s 0 0;

          .image {
            width: 70px;
          }

          picture img {
            border-radius: 50%;
          }
        }

      }
    }
  }

  &__buttonheading {
    margin-bottom: $spacing--xs;
  }

  .map {
    width: auto;
    height: 272px;
    margin-top: $spacing--s;
  }

  .button {

    &:hover .button__text {
      color: white;
    }

    &:hover .button__text-container {
      background: $color--blue--dark;
    }

    &:hover path {
      fill: white !important;
    }

    path {
      fill: $color--blue--dark;
    }

    &__text {
      font-size: 16px;
      color: $color--blue--dark;

      &-container {
        background: transparent;
        height: fit-content;
        border-width: thin;
      }

    }
  }

  &__toggle-button {
    @include button-style-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    background-color: $color--blue--logo;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding-top: $spacing--xs;
    padding-bottom: $spacing--xs;
    margin-top: $spacing--xs;

    @include breakpoint-l {
      display: none;
    }

    &-arrow {
      transform: rotate(90deg);
      transition: transform 0.3s;
      margin-left: $spacing--xxs;
      height: 12px;
      width: 14px;
      margin-top: 3px;

      &--is-open {
        transition: transform 0.3s;
        transform: rotate(-90deg);
        margin-top: 0;
      }

      path {
        fill: $color--blue--dark;
      }
    }
  }

  &__toggle {
    overflow: hidden;
  }

  &__container {
    overflow: hidden;
    transition: all 0.3s;

    &--is-open {
      transition: all 0.3s;
    }

    @include breakpoint-l {
      height: auto !important;
    }
  }

  .rich-text p {
    font-size: 16px;
    margin-bottom: 0;
  }
}