@import '/src/scss/main';

.quoteblock {
  @include content-wrapper;
  background: $color--blue--dark;
  color: white;
  padding: $spacing--m 0;
  margin-bottom: $spacing--s;
  
  @include breakpoint-m {
    padding: $spacing--l 0;
  }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    @include breakpoint-s {
      flex-flow: row;
    }

    &-text {
      display: flex;
      flex-direction: column;
      margin: $spacing--s $spacing--s $spacing--s $spacing--s;
    
      @include breakpoint-s {
        margin: 0 $spacing--s $spacing--xl $spacing--s;
      }

      &-quote {
        @include responsive-font-size(20px, 25px);
        line-height: 35px;
        font-weight: 200;
        min-width:auto;

        @include breakpoint-s {
          margin-right: $spacing--sm;
        }
      }

      &-author {
        letter-spacing: 0.5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }

  &__image {
    min-width: 250px;
    max-width: fit-content;
    margin-left: 0;
    margin-right: $spacing--s;
    aspect-ratio: 16/16;
    position: relative;
    width: 70%;
    border-radius: 50%;
    overflow: hidden;

    @include breakpoint-s{
      margin-left: $spacing--m;
      width: 100%;     
    }

    @include breakpoint-m{
      width: 60%;
    }

    picture img {
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      right: 0;
      bottom:0;
      left:0;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
    }
  }
}
