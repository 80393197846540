@import '/src/scss/main';

.location-card-list {
  $self: &;
  background: $color--blue--grey;
  color: $color--blue--dark;

  &__content {
    padding: $spacing--m 0;
    @include content-wrapper;
  }

  &__items {
    @include grid-4-items;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__items-container {
    margin-top: $spacing--s;
  }

  &__link {
    @include arrow-hover;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color--blue--dark;
    font-size: 21px;
    margin-top: $spacing--sm;

    &-icon {
      width: 40px;
      height: 21px;
      margin-left: $spacing--xs;
    }
  }

  &__item {
    font-size: 16px;
    line-height: 19px;
    background: white;
    padding: $spacing--xs;
    margin-bottom: $spacing--xs;

    &:hover {
      background-color: $color--blue--dark;

      & #{$self}__item-link {
        color: white;
        border-bottom: 1px solid white;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }

      & #{$self}__item-content {
        & #{$self}__item-icon svg path {
          fill: white;
        }
      }
    }

    &:hover #{$self}__item-top-link {
      color: white;

      h3 {
        color: white;
      }

      & #{$self}__item-icon svg path {
        fill: white;
      }


    }


    @include breakpoint-m {
      margin-bottom: 0;
    }

    &-link {
      color: $color--blue--dark;
      text-decoration: none;
      padding-bottom: 1px;
      transition: border-bottom 0.3s;
      border-bottom: 1px solid $color--blue--dark;

      &:hover {
        border-bottom: 1px solid transparent;
      }

    }

    &-content {
      display: flex;
      margin-top: $spacing--xxs;
    }

    &-icon {
      margin-right: $spacing--xs;
    }

    &-top-link {
      text-decoration: none;
      color: $color--blue--dark;

    }
  }
}