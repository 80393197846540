@import 'src/scss/main';

.article-card {
  display: flex;
  flex-direction: column;
  @include content-wrapper;


  &__title {
    margin-bottom: $spacing--sm;
  }

  &__cards {
    display: block;
    @include grid-4-items;
    grid-column-gap: $spacing--sm !important;
    grid-row-gap: $spacing--l !important;
  }

  &__link {
    @include arrow-hover;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color--blue--dark;
    font-size: 21px;
    margin-top: $spacing--sm;

    &-icon {
      width: 40px;
      height: 21px;
      margin-left: $spacing--xs;
    }
  }

  &__card {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: $spacing--m;

    &:hover {
      img {
        transform: scale(1.1);
        transition: all 0.3s ease-out;
      }
    }

    @include breakpoint-m {
      margin-bottom: 0;
    }

    &-link {
      display: flex;
      text-decoration: none;
      color: $color--blue--dark;
      font-weight: 450;

      &-icon {
        margin-left: $spacing--xs;
        margin-top: 1px;
      }
    }

    &-image {
      margin-bottom: 16px;
      overflow: hidden;

      img {
        transition: all 0.3s ease-out;
      }
    }

    &-top-link {
      text-decoration: none;
      color: $color--blue--dark;
    }

    &-content {
      display: flex;
      flex-flow: column wrap;
      margin-bottom: 16px;
    }

    &-description {
      margin-top: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}