main {
  flex: 1 0 auto;
  flex-grow: 1;
  min-height: 100vh;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 82px;

  @include breakpoint-l {
    padding-top: 155px;
  }
}

.content-area__item {
  padding: 40px 0 40px 0;
}

.iframe-container {
  margin-bottom: 2rem;
}

.CookieDeclaration {
  max-width: 1200px;
  margin: 0 auto;
}
