@import '/src/scss/main';

.breadcrumb {
  display: flex;

  &__crumbs {
    display: none;

    @include breakpoint-m {
      display: flex;
      color: $color--blue--dark;
      text-decoration: none;
      padding-bottom: 1px;
      transition: border-bottom 0.3s;
      border-bottom: 1px solid $color--orange;
      margin: 0 $spacing--xxxs 0 $spacing--xxxs;

      &:hover {
        border-bottom: 1px transparent solid;
      }
    }
  }

  //targets second last crumb
  &:nth-last-child(2) > a {
    display: flex;
    color: $color--blue--dark;
    text-decoration: none;
    margin: -3px 0 0 $spacing--xxxs;
    font-size: larger;
    padding-bottom: 1px;
    transition: border-bottom 0.3s;
    border-bottom: 1px solid $color--orange;

    @include breakpoint-m {
      font-size: medium;
      margin: 0 0 0 $spacing--xxxs;
      &:hover {
        border-bottom: 1px transparent solid;
      }
    }
  }

  &:last-child > a {
     color: $color--blue--dark;
     text-decoration: none;
     font-size: medium;
     border-bottom: hidden;
     pointer-events: none;
  }

  &__connector {
    display: none;

    @include breakpoint-m {
      display: flex;
      color: $color--blue--dark;
      margin: 0 $spacing--xxxs 0 $spacing--xxxs;
    }
  }

  //targets last connector
  &:last-child > div {
    display: none;
  }

  &-list {
    @include content-wrapper;
    display: flex;
    flex-flow: row;
    margin-top: $spacing--xs;

    @include breakpoint-l {
      margin-top: $spacing--m;
    }
  }

  &-list__arrow {
    svg {
      padding-top: $spacing--xxxs;
      margin-right: $spacing--xxxs;
    }
    
    @include breakpoint-m {
      display: none;
    }
  }
}
