@import '/src/scss/main';

.hero {
  margin-top: $spacing--sm;
  margin-bottom: $spacing--sm;
  color: $color--blue--dark;

  @include breakpoint-m {
    margin-bottom: $spacing--xl;
    margin-top: $spacing--m;
  }

  &__description-container {
    @include content-wrapper;
    max-width: 1200px !important;
  }

  &__description {
    font-size: 21px;
    line-height: 1.19;
    margin-top: $spacing--xs;

    @include breakpoint-m {
      font-size: 37px;
      max-width: 85%;
      margin-top: $spacing--sm;
      font-weight: 200;
    }
  }

  .heading {
    @include content-wrapper;
    max-width: 1200px !important;
    font-size: 37px;
    @include breakpoint-m {
      font-size: 67px;
    }
  }

  &__image-container {
    @include content-wrapper;
  }

  &__image {
    aspect-ratio: 327 / 220;
    margin-bottom: $spacing--sm;
    position: relative;

    @include breakpoint-m {
      aspect-ratio: 1312 / 547;
      margin-bottom: $spacing--m;
    }

    picture img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
    }
  }
}
