.epi-editor {
  padding: 0;
  margin: 8px;

  color: $color--blue--dark;
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  h2 {
    @include responsive-font-size(37px, 50px);
    margin: 0 0 $spacing--xs 0;
  }

  h3 {
    @include responsive-font-size(21px, 37px);
    margin: 0 0 $spacing--xxs 0;
  }

  h4 {
    @include responsive-font-size(16px, 21px);
    margin: 0 0 $spacing--xxxs 0;
  }

  p,
  ul,
  ol {
    @include responsive-font-size(16px, 21px);
    margin: 0 0 $spacing--sm 0;
    line-height: 1.33;
  }

  a {
    color: $color--link;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  ol {
    padding: 0 0 0 $spacing--sm;
  }

  b,
  strong {
    font-weight: 500;
  }
}
