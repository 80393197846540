@import '/src/scss/main';

.button {
  $self: &;
  display: flex;
  width: fit-content;
  align-items: center;
  text-decoration: none;

  &--color-blue-dark {
    color: white !important;

    &:hover {
      color: $color--blue--dark !important;
    }

    &:hover #{$self}__arrow svg path {
      fill: $color--blue--dark;
    }

  }

  &--color-blue-light {
    color: $color--blue--dark !important;

    &:hover {
      color: $color--blue--light !important;
    }
  }

  &:hover &__text-container {
    background: transparent;
  }

  &__text-container {
    margin-bottom: 4px;
    margin-left: 2px;
    border-radius: 52px;
    padding-right: $spacing--s;
    padding-left: $spacing--s;
    padding-top: $spacing--xxs;
    padding-bottom: $spacing--xxs;
    display: flex;
    align-items: center;

    #{$self}--color-blue-dark & {
      background: $color--blue--dark;
      border: 3px solid $color--blue--dark;
    }

    #{$self}--color-blue-light & {
      background: $color--blue--light;
      border: 3px solid $color--blue--light;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 2px;

    @include breakpoint-m {
      font-size: 21px;
      line-height: 1.3;
    }
  }

  &__arrow {
    font-size: 21px;
    line-height: 1.3;
    font-weight: 500;
    margin-left: 13px;
  }
}

.icon-thin-chevron-left {
    background-color: #1e4661;
    -webkit-mask-image: url('../../assets/icons/chevron-left.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 6px, auto;
    mask-image: url('../../assets/icons/chevron-left.svg');
    mask-repeat: no-repeat;
    mask-size: 6px, auto;
}