.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.d-flex {
  display: flex !important;
}
.h-100 {
  height: 100% !important;
}
.p-0 {
  padding: 0 !important;
}
.pl-16{
  padding-left: 16%;
}
.w-100 {
  width: 100% !important;
}
.col-md-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-md-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-lg-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-lg-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.mb-6 {
  margin-bottom: 4.5rem !important;
}
.mb-md-0 {
  margin-bottom: 0 !important;
}
.offset-lg-1 {
  margin-left: 8.33333333%;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.position-fixed {
  position: fixed !important;
}
