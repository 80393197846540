@import '/src/scss/main';

.heading {
  margin: 0;

  &--size {
    &-xxl {
      font-size: convert-to-rem(89px);
    }

    &-xl {
      @include responsive-font-size(50px, 67px);
      line-height: 1.16;
      font-weight: 500;
    }

    &-lg {
      @include responsive-font-size(37px, 50px);
      line-height: 1.16;
      font-weight: 500;
    }

    &-md {
      font-size: convert-to-rem(37px);
    }

    &-sm {
      @include responsive-font-size(21px, 26px);
      font-weight: 500;
    }

    &-xs {
      font-size: convert-to-rem(21px);
      line-height: 1.35;
      font-weight: 500;
    }
  }

  &--color {
    &-blue-dark {
      color: $color--blue--dark;
    }

    &-blue-light {
      color: $color--blue--light;
    }

    &-white {
      color: white;
    }

    &-black {
      color: black;
    }
  }
}
