@import '/src/scss/main';

.locations-map {
    @include content-wrapper;
    display: flex;

    &__map {
        width: 100%;
        position: relative;
    }

    .map {
        width: auto !important;
        height: 60vh !important;
    }

    &__locations {
        display:none;
        background-color: $color--blue--light;
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 60vh;
        overflow-y: auto;
        width: 40%;
        height: fit-content;

        @include breakpoint-m{
            display: block;
        }
    }

    &__location {
        display: none;
        padding: $spacing--s;
        border-bottom: 1px solid $color--blue--dark;
        &--is-selected {
            background-color: rgba(255, 255, 255, 0.8)
        }

        &--is-visible {
            display: block;
        }
    }

    
      a {  
            @include arrow-hover;
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            color: $color--blue--dark;
            font-weight: 500;
        }

        .arrow {
            margin-top: 2px;
            width: 26px;
        }
    

    &__info {
        color: $color--blue--dark;
        margin-top: $spacing--xxs;
        font-size: 16px;
    }

    &__zoom--in,
    &__zoom--out {
        background-color: $color--blue--dark;
        border-radius: 50%;
        border: 2px solid transparent !important;
        width: 35px;
        height: 35px;
        cursor: pointer;
        text-align:center;

        @include breakpoint-m {
            width: 44px;
            height: 44px;
        }
    }

    &__zoom--out {
        margin-top: $spacing--xxs;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;

        @include breakpoint-m {
            margin-right: 24px;
            margin-top: 24px;
        }
    }

    &__zoom--in {
        margin-top: $spacing--xxs;
        margin-bottom: 22px;
        margin-left: 10px;

        @include breakpoint-m {
            margin-top: 24px;
            margin-bottom: 22px;
            margin-left: 10px;
        }
    }

    &__zoom--in:hover,
    &__zoom--out:hover {
        background-color: $color--blue--light !important;
        border: 2px solid $color--blue--dark !important;
    }

    &__zoom--in:hover .locations-map__zoom-text,
    &__zoom--out:hover .locations-map__zoom-text {
        color: $color--blue--dark !important;
    }

    &__zoom-text {
        line-height: 36px;
        user-select: none;

        @include breakpoint-m {
            line-height: 44px;
        }
    }

    &__search {
        border-radius: 26px;
        border: 2px solid $color--blue--dark;
        background-color: $color--blue--light;
        font-size: 16px;
        color: $color--blue--dark;
        padding: 8px 12px;
        margin-top: $spacing--xxs;
        margin-left: 10px;
        outline: none;

        @include breakpoint-m {
            font-size: 21px;
            margin-left: $spacing--s;
            margin-top: $spacing--s;
        }

        &::placeholder {
            color: $color--blue--dark;
        }
    }

    &__loading {
        display: none;
        &--is-loading {
            display: block;
            color: white;
            position: absolute;
            z-index: 99;
            width: 100%;
            height: 100%;
            background: rgba(black, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 18px;
        }
    }
}