@import '/src/scss/main';

.large-number {
  $self: &;
  @include content-wrapper;
  list-style: none;
  padding: 0;

  &--one-card {
    display: grid;
  }

  &--two-card {
    @include breakpoint-s {
      display: flex;
      flex-direction: column;
    }

    @include breakpoint-m {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  &--three-card {
    @include breakpoint-s {
      display: flex;
      flex-direction: column;
    }

    @include breakpoint-m {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  &__card {
    height: 152px;

    #{$self}--two-card & {
      margin: 0 0 16px 0;
      @include breakpoint-m {
        &:first-child {
          margin: 0 16px 8px 0;
        }
        &:last-child {
          margin: 0 0 8px 16px;
        }
      }
    }

    #{$self}--three-card & {
      margin: 0 0 16px 0;
      @include breakpoint-l {
        &:first-child {
          margin: 0 16px 8px 0 !important;
        }

        &:nth-child(2) {
          margin: 0 16px 8px 16px !important;
        }

        &:last-child {
          margin: 0 0 8px 16px !important;
        }
      }

      @include breakpoint-m {
        &:first-child {
          margin: 0 8px 16px 0;
        }

        &:nth-child(2) {
          margin: 0 0 8px 8px;
        }

        &:last-child {
          margin: 0 0 16px 0;
        }
      }
    }

    &--blue {
      background-color: $color--blue--light;
      color: $color--blue--dark;
    }

    &--pink {
      background-color: $color--pink;
      color: $color--blue--grey;
    }

    &--orange {
      background-color: $color--orange;
      color: $color--blue--grey;
    }

    &--green {
      background-color: $color--green;
      color: $color--blue--grey;
    }

    &--beige {
      background-color: $color--beige;
      color: $color--blue--dark;
    }

    &--darkblue {
      background-color: $color--blue--dark;
      color: $color--blue--grey;
    }

    &--label {
      @include responsive-font-size(18px, 21px);
      height: 28px;
      margin: 24px 24px 8px 24px;
      line-height: 28px;
    }

    &--short-text {
      font-size: 47px;
      margin: 0 24px 16px 24px;
      font-weight: 500;
      line-height: 76px;
    }
  }

  &--three-card &__card {
    display: flex;
    flex-direction: column;

    @include breakpoint-m {
      flex: 1 0 33%;
    }

    @include breakpoint-l {
      flex-basis: 30%;
    }
  }

  &--two-card &__card {
    display: flex;
    flex-direction: column;

    @include breakpoint-m {
      flex: 1 0 30%;
    }

    @include breakpoint-l {
      flex-basis: 40%;
    }
  }
}
