@import 'src/scss/main';
.menu-search {
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &__input-field {
    background: white;
    border: 2px solid $color--blue--dark;
    border-radius: 40px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: $spacing--xs;
    padding-right: $spacing--xs;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    transition: width 0.3s;
    position: absolute;

  &--is-open {
      position: relative;
      width: 270px;
      visibility: visible;
      transition: width 0.3s;

      @include breakpoint-m{
        width: 350px;
      }
  }

    &-text {
      font-size: convert-to-rem(16px);
      color: $color--blue--dark;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $color--blue--dark;
      height: 20px;
      width: 200px;

      @include breakpoint-m{
        width: 350px;
      }
      
      &:focus-visible {
        outline: none;
      }
   
    }
  }

  &__icon {
    @include button-style-reset;
    cursor: pointer;
    background: white;
    margin-right: $spacing--xxs;
  }

  &__search-button {
    @include button-style-reset;
    background: transparent;
    font-size: convert-to-rem(16px);
    font-weight: 500;
    color: $color--blue--dark;
    padding: 0;
    margin-left: $spacing--xxs;
    cursor: pointer;
  }

  &__button {
    @include button-style-reset;
    background: white;
    border: 2px solid $color--blue--dark;
    border-radius: 40px;
    height: 36px;
    justify-content: center;
    align-items: center;display: flex;
    padding-left: $spacing--xs;
    padding-right: $spacing--xs;
    cursor:pointer;
  }
}
