@import 'src/scss/main';
.search-input {
  @include content-wrapper;
  max-width: 1200px !important;
  margin-bottom: $spacing--sm;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &__input-field {
    border: 2px solid $color--blue--dark;
    border-radius: 40px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: $spacing--xs;
    padding-right: $spacing--xs;

    &:focus-within{
      border: 2px solid $color--orange;
    }

    @include breakpoint-sm {
      width: 530px;
    }

    &-text {
      font-size: convert-to-rem(21px);
      color: $color--blue--dark;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $color--blue--dark;
      height: 30px;
      width: 100%;
      outline: none;
    }
  }

  &__icon {
    margin-right: $spacing--xxs;
  }

  &__button {
    @include button-style-reset;
    background: transparent;
    font-size: convert-to-rem(21px);
    color: $color--blue--dark;
    padding: 0;
    margin-left: $spacing--xxs;
    cursor: pointer;
  }
}
