@import 'src/scss/main';

.promo {
  background: $color--blue--light;
  margin-bottom: $spacing--s;

  &__content {
    @include content-wrapper;
    padding-top: $spacing--sm;
    padding-bottom: $spacing--sm;

    @include breakpoint-m {
      padding-top: $spacing--xl;
      padding-bottom: $spacing--xl;
    }
  }

  &__title {
    margin-bottom: $spacing--xxs;
  }

  &__buttons {
      display: flex;
      flex-wrap: wrap;
  }

  &__text {
    max-width: 950px;
  }

  .rich-text p {
    margin: 0 0 $spacing--xxs 0;
  }

  &__button {
    margin-top: $spacing--xs;
    margin-bottom: $spacing--xxs; 
    margin-right: $spacing--xs;
    @include breakpoint-m {
      margin-bottom: 0;
     
    }
    
  }
}
