@import 'src/scss/main';

.location-shortcut {
    @include content-wrapper;
    display: flex;
    flex-direction: column;
    margin-top: $spacing--s;

    ul {
        columns: 1;
        padding-left: 0;
        margin-bottom: $spacing--s;
    }

    li {
        background-color: $color--blue--light;
        list-style: none;
        border-bottom: 1px solid rgba(53, 65, 82, 0.5);
        break-inside: avoid;
        font-weight: 500;
    }

    &__link {
        @include arrow-hover;
        a {
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            color: $color--blue--dark;
            padding: 16px;
        }

        &-arrow {
            margin-top: 2px;
            width: 26px;
        }
    }
}