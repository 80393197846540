@import '/src/scss/main';
.footer {
  background: $color--blue--dark;
  color: white;
  padding: $spacing--m 0;
  @include breakpoint-m {
    padding: $spacing--l 0;
  }

  &__content-container {
    @include content-wrapper;
  }

  &__content {
    @include grid-4-items;
  }

  &__logo {
    margin-bottom: $spacing--m;
    @include breakpoint-m {
      margin-bottom: 0;
    }
  }

  &__group {
    margin-top: $spacing--sm;

    @include breakpoint-m {
      margin-top: 0;
    }
  }

  &__link-list {
    list-style: none;
    padding: 0;
    margin: $spacing--xs 0 0 0;
  }

  &__link-container {
    margin-bottom: $spacing--xxs;
    display: flex;
    align-items: center;
  }

  &__link {
    @include responsive-font-size(16px, 21px);
    color: white;     
    text-decoration: none;
    padding-bottom: 1px;
    transition: border-bottom 0.3s;
    border-bottom: 2px solid transparent;
    
    &:hover {
        border-bottom: 2px solid $color--orange;
    }  
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: $spacing--xxs;

    svg {
      width: 17px;
      height: 17px;
    }

    @include breakpoint-s {
      svg {
        width: 21px;
        height: 21px;
      }
    }
  }
}
