@import 'colors';
@import 'functions';
@import 'mixins';
@import 'vars';
@import 'layout';
@import 'rich-text';
@import 'rich-text-block';
@import 'image-block';
@import 'table';
@import 'epi-editor';

@font-face {
  font-family: 'Soehne';
  src: local('Soehne'), url(../assets/fonts/soehne-buch.woff2) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Soehne';
  src: local('Soehne'), url(../assets/fonts/soehne-leicht.woff2) format('woff');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne';
  src: local('Soehne'), url(../assets/fonts/soehne-kraftig.woff2) format('woff');
  font-weight: 500;
  font-display: swap;
}
