@import '/src/scss/main';

.embedded-youtube-video {
    @include content-wrapper;
    font-size: 1.1rem;
    margin-top: $spacing--sm;
    margin-bottom: $spacing--sm;
    color: $color--blue--dark;
    width: calc(100% - calc($spacing--s * 2));

    @include breakpoint-s {
        padding-left: $spacing--l;
        padding-right: $spacing--l;
        width: calc(100% - calc($spacing--l * 2));
    }

    @include breakpoint-m {
        margin-bottom: $spacing--xl;
        margin-top: $spacing--m;
        width: calc(100% - calc($spacing--xxl * 2))
    }

    &-container {
        width: 100%;
        position: relative;
    }

    .youtube-player-play-button {
        background-image: url("../../assets/icons/playvideo-dark.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        cursor: pointer;
        transition: ease 0.2s;

        &:hover {
            background-image: url("../../assets/icons/playvideo-light.svg");
            transition: ease 0.2s;
        }
    }
}