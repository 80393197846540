@import '/src/scss/main';
.search {
  margin-bottom: $spacing--xxl;
  &__result {
    margin-top: $spacing--sm;
    margin-bottom: $spacing--xs;
    @include breakpoint-m {
      margin-top: $spacing--l;
      margin-bottom: $spacing--xl;
    }
    
    animation: fade-in 1s;

  @keyframes fade-in {
    0% {
      transform:translateY(20px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform:translateY(0);
    }
  }

  &--is-loading {
    animation: fade-out 1s;
    @keyframes fade-out {
      0% {
        transform:translateY(0);
        opacity: 0;
      }
      100% {
        opacity: 1;
        transform:translateY(20px);
      }
    }
  }
  }

  &__no-results {
    @include content-wrapper;
    max-width: 1200px !important;
    margin-top: $spacing--m;
    margin-bottom: $spacing--sm;
    font-size: convert-to-rem(21px);
    color: $color--blue--dark;
    font-weight: 300;
    @include breakpoint-m {
      margin-top: $spacing--l;
      margin-bottom: $spacing--xl;
      font-size: convert-to-rem(37px);
    }
  }

  &__spinner-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: $spacing--l;
    margin-bottom: $spacing--l;
    @include breakpoint-m {
      margin-top: $spacing--xxl;
      margin-bottom: $spacing--xl;
    }
  }

  &__spinner,
  &__spinner:after {
    border-radius: 50%;
    width: 4em;
    height: 4em;
  }

  &__spinner {
    border-top: 0.6em solid $color--blue--dark;
    border-right: 0.6em solid $color--blue--dark;
    border-bottom: 0.6em solid $color--blue--dark;
    border-left: 0.6em solid $color--blue--light;
    animation: spinner 1.1s infinite linear;
    opacity: 0;
  }

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      opacity: 1;
    }
  }

}
