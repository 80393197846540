@import '/src/scss/main';

.map {
    height: 80vw;
    width: 100vw;

    @include breakpoint-s {
        height: 60vw;
        width: 100vw;
    }

    @include breakpoint-m {
        height: 46vw;
        width: 100vw;
    }

    &__text {
        display: flex;
        margin-top: 16px;

        &--caption {
            font-weight: bold;
            font-size: 16px;
            float: left;
            margin-right: 4px;
            color: $color--blue--dark;
        }
    }
}
