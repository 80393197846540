@import 'src/scss/main';

.category-tags {
  @include content-wrapper;
  max-width: 1200px !important;
  margin-bottom: $spacing--m;

  @include breakpoint-l {
    margin-bottom: $spacing--l;
  }

  &__title {
    margin-bottom: $spacing--xs;
    display: none;

    @include breakpoint-l {
      display: block;
    }
  }

  &__container {
    max-width: 380px;
    @include breakpoint-l {
      max-width: none;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease-out;
    visibility: hidden;

    &--is-open {
      visibility: visible;
    }

    &--mobile {
      overflow: hidden;
      display: block;
      border-radius: 0 0 25px 25px;
      border-left: 2px solid $color--blue--dark;
      border-right: 2px solid $color--blue--dark;
      border-bottom: 2px solid $color--blue--dark;
      background-color: $color--blue--light;
      @include breakpoint-l {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @include breakpoint-l {
        display: flex;
        visibility: visible !important;
      }
    }
  }

  &__item {
    display: flex;
    padding: 0 $spacing--xs;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: $color--blue--dark;
      a {
        color: white;
      }
      @include breakpoint-l {
        background: transparent;
      }
    }

    @include breakpoint-l {
      margin-left: $spacing--xxs;
      margin-right: $spacing--xxs;
      min-width: 135px;
      border-bottom: 0;
      padding: 0;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      font-size: 16px;
      font-weight: 500;
      color: $color--blue--dark;
      display: flex;
      min-width: 100%;
      padding: $spacing--xs 0;
      line-height: 1;
      text-decoration: none;
      border-bottom: 1px solid rgba(53, 65, 82, 0.5);

      @include breakpoint-l {
        &:hover {
          color: $color--blue--dark;
          background: $color--blue--light;
        }
        border-radius: 14px;
        border-bottom: 0;
        background: $color--blue--dark;
        color: white;
        border: 2px solid $color--blue--dark;
        text-align: center;
        justify-content: center;
        padding: $spacing--xxxs 0;
      }
    }
  }

  &__button-container {
    .button__text-container {
      margin-bottom: 0;
      width: 100%;
      &:hover {
        background: $color--blue--light;

        .button__arrow {
          svg path {
            fill: $color--blue--dark;
          }
        }
      }
    }

    @include breakpoint-l {
      display: none;
    }
  }

  &__button {
    background: transparent;
    border: none;
    padding: 0;
    width: 100%;

    .button__text-container {
      border: 2px solid $color--blue--dark;
      margin: 0;
      padding-left: $spacing--xs;
      transition: border-radius 0.1s;
      transition-delay: 0.25s;
      display: flex;
      justify-content: space-between;
    }

    .button__text {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }

    .button__arrow {
      display: flex;
      align-items: center;
      transition: all 0.3s;
      transform-origin: center;
      svg path {
        fill: white;
      }
    }

    &--is-open {
      .button__text-container {
        color: $color--blue--dark;
        background: $color--blue--light;
        border-radius: 25px 25px 0 0;
        transition: border-radius 0.1s;
        transition-delay: 0s;
      }

      .button__arrow {
        transform: rotate(-180deg);
        transform-origin: center;
        transition: all 0.3s;
        svg path {
          fill: $color--blue--dark;
        }
      }
    }
  }
}
