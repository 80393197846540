@import '/src/scss/main';

.employee-cards {
  @include content-wrapper;

  &__block-title {
    margin-bottom: $spacing--sm;
  }

  &__items {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: $spacing--sm;
    grid-row-gap: $spacing--sm;
    list-style: none;
    padding: 0;
    margin: 0;

    @include breakpoint-m {
      $column-width: calc(50% - $spacing--sm * 1 / 2);
      grid-template-columns: $column-width $column-width;
      grid-column-gap: $spacing--sm;
    }

    @include breakpoint-l {
      $column-width: calc(33.3% - $spacing--sm * 2 / 3);
      grid-template-columns: $column-width $column-width $column-width;
      grid-column-gap: $spacing--sm;
    }
  }

  &__item {
    width: 100%;
    background-color: $color--blue--dark;
  }

  &__link-container {
    @include arrow-hover;
  }

  &__content {
    margin: $spacing--xs;
    display: flex;
    flex-direction: row-reverse;
  }

  &__contact {
    display: flex;
    margin-top: $spacing--xxxs;
    word-break: break-word;

    &-text {
      color: white;
      text-decoration: none;
      padding-bottom: 1px;
      transition: border-bottom 0.3s;
      border-bottom: 1px solid white;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }

    &-icon {
      margin-right: $spacing--xxs;
      path {
        fill: white;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }

  &__link-disabled {
    text-decoration: none;
    color: white;
  }

  &__info {
    .heading {
      font-size: 16px;
      color: white;
    }
    width: 70%;
    font-size: 16px;
    line-height: 1.2;
    margin-left: $spacing--xs;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: $spacing--xxs;
    height: 15px;
    svg {
      width: 25px;
    }
  }

  &__card-title {
    margin-top: $spacing--xxxs;
    margin-bottom: $spacing--xxxs;
  }

  &__locations {
    margin-bottom: $spacing--xxs;
  }

  &__image {
    aspect-ratio: 16 / 16;
    position: relative;
    width: 30%;
    border-radius: 50%;
    overflow: hidden;
    height: fit-content;

    picture img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
    }
  }

  &__link-to-more {
    @include arrow-hover;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color--blue--dark;
    font-size: 21px;
    margin-top: $spacing--sm;

    &-icon {
      width: 40px;
      height: 21px;
      margin-left: $spacing--xs;
    }
  }
}
